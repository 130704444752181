<template>
  <div class="workbench">
    <div class="content content-left">
      <NoCompany v-if="userInfo.isPersonal" :currentUserInfo="currentUserInfo" />
      <Company v-if="!userInfo.isPersonal" :currentUserInfo="currentUserInfo" />
      <AuthStep v-if="!userInfo.isPersonal && userInfo.authenticationStatus === '1'" />
      <Deck />
      <!-- <Cooperation /> -->
      <!-- <MineTask /> -->
      <ProcessApproval />
      <MineMessage />
    </div>
    <div class="content content-right">
      <Notices />
      <div class="panel shuzipuhui">
        <div class="title">数字普惠</div>
        <div class="box bg-1" style="margin-bottom: 10px;">
          <img :src="require('@/assets/imgs/workbench/pic_gongxvtong.svg')" class="pic" />
          <div class="name" style="color: #000A35;">供需通</div>
          <div class="desc" style="color: #000A35;">医械后市场采销协同开放平台</div>
          <div @click="toJoinGongXvTong" class="toPath" style="background-color: #3176FF;">去了解</div>
        </div>
        <div class="box bg-2" style="margin-bottom: 10px;">
          <div class="name" style="color: #5D3F25;">加盟服务</div>
          <div class="desc" style="color: #835B38;">共享资源，专业技术支持</div>
          <div @click="toJoinXiaozi" class="toPath" style="background-color: #835B38;;">去了解</div>
        </div>
        <div class="box bg-3">
          <div class="name" style="color: #333E5D;">医修保</div>
          <div class="desc" style="color: #596CA1;">优质服务，5000+服务商的选择</div>
          <div @click="toJoinYixiubao" class="toPath" style="background-color: #3176FF;">去了解</div>
        </div>
      </div>
      <div class="panel yixiubao">
        <div class="title">医修保</div>
        <div class="box-1">
          <div class="name">工单数据看板</div>
          <div @click="toOrderWall" class="toPath" style="background-color: #3176FF;">进入看板</div>
        </div>
        <div class="box-2">
          <div class="cont">
            <div class="data">
              <span class="line"></span>
              <span>待处理工单数据</span>
            </div>
            <div class="data">
              <span class="line"></span>
              <span>工单进度分析</span>
            </div>
          </div>
          <div class="cont">
            <div class="data">
              <span class="line"></span>
              <span>数据分析统计</span>
            </div>
            <div class="data">
              <span class="line"></span>
              <span>工单评价</span>
            </div>
          </div>
        </div>
      </div>
      <div class="panel yundingdan">
        <div class="title">云市场订单</div>
        <div class="box">
          <div v-for="(item, index) in orderList" :key="'order-t-'+index" class="count-box" @click="handleClickOrder(item.value)">
            <img :src="item.icon" class="icon"/>
            <span class="name">{{ item.name }}</span>
            <span class="count">{{ item.count }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 创建企业 -->
    <el-dialog
      :visible.sync="createdDialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="closeForbid"
      width="520px"
      class="created-business">
      <div slot="title" class="title">暂无关联企业，请先创建企业</div>
      <el-form :model="addCompanyForm" :rules="addCompanyFormRules" label-position="top" ref="addCompanyForm" style="padding: 0 20px;">
        <el-form-item label="企业名称" prop="company">
          <el-input placeholder="请输入" v-model.trim="addCompanyForm.company"></el-input>
        </el-form-item>
        <el-form-item label="经营类型" prop="businessType">
          <el-radio-group v-model="addCompanyForm.businessType">
            <el-radio v-for="(item, index) in businessTypeList" :key="'type-'+index" :label="item.type" style="margin-bottom: 15px;">{{ item.label }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="save(1)" size="small" type="primary">保 存</el-button>
        <el-button @click="save(2)" size="small" type="primary">保存并认证</el-button>
      </span>
    </el-dialog>
    <!-- 姓名、职位/职业 -->
    <el-dialog
      :visible.sync="addUserInfoVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="closeForbid"
      width="520px"
      class="created-business">
      <div slot="title" class="title">为了更好的为您提供服务，请完善个人资料</div>
      <el-form :model="addUserInfoForm" :rules="addUserInfoFormRules" label-position="top" ref="addUserInfoForm" style="padding: 0 20px;">
        <el-form-item label="请选择职业/职位" prop="profession">
          <el-radio-group v-model="addUserInfoForm.profession">
            <el-radio v-for="(item, index) in professionList" :key="'profess-'+index" :label="item.type" style="margin-bottom: 15px;">{{ item.label }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="请填写真实姓名" prop="name">
          <el-input placeholder="请输入" v-model.trim="addUserInfoForm.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="saveUserInfo" size="small" type="primary">保 存</el-button>
      </span>
    </el-dialog>

    <CreateBusinessDialog ref="createBusiness"></CreateBusinessDialog>
    <CompanyListDialog ref="selectBusiness"></CompanyListDialog>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import { BusinessTypeListEnum } from '@/enums/BusinessTypeListEnum';
import { ProfessionEnum } from '@/enums/ProfessionEnum';
import { check, tenantBaseInfo, createTenant } from '@/api/company';
import AccountApi from '@/api/login';
import SystemSetting from '@/api/systemSetting';
import OrderApi from '@/api/order';
import switchCompany from '@/utils/switchCompany';
import NoCompany from './Components/noCompany.vue';
import AuthStep from './Components/authStep.vue';
import Company from './Components/company.vue';
import Deck from './Components/deck.vue';
// import Cooperation from './Components/cooperation.vue';
// import MineTask from './Components/mineTask.vue';
import ProcessApproval from './Components/processApproval.vue';
import MineMessage from './Components/mineMessage.vue';
import Notices from './Components/notices.vue';
import { CreateBusinessDialog, CompanyListDialog } from '@/components/Dialog';

export default {
  components: {
    NoCompany,
    AuthStep,
    Company,
    Deck,
    // Cooperation,
    // MineTask,
    ProcessApproval,
    MineMessage,
    CreateBusinessDialog,
    CompanyListDialog,
    Notices,
  },
  computed: {
    ...mapGetters(['currentUserInfo']),
  },
  data () {
    let nameValidator = (rule, value, callback) => {
      const reg = /^[a-zA-Z\u4e00-\u9fa5]+$/;
      if (value === '') {
        callback(new Error('请输入真实姓名'));
      } else if (!reg.test(value)) {
        callback(new Error('姓名格式有误'));
      } else {
        callback();
      }
    };
    return {
      userInfo: {},
      isSupplier: false,
      entryStatus: {},
      haveCompany: '',
      openYixiubao: false,
      orderList: [
        {
          icon: require('@/assets/imgs/workbench/ic_daifukuan.svg'),
          name: '待付款',
          count: 0,
          value: '1',
        },
        {
          icon: require('@/assets/imgs/workbench/ic_yifukuan.svg'),
          name: '已付款',
          count: 0,
          value: '2',
        },
        {
          icon: require('@/assets/imgs/workbench/ic_yikaipiao.svg'),
          name: '已开票',
          count: 0,
          value: '3',
        },
      ],
      platformInfo: [
        {
          name: '加盟服务',
          status: '未入驻',
        },
        {
          name: '医修保',
          status: '未开通',
        },
      ],
      createdDialogVisible: false,
      addCompanyForm: {
        company: '',
        businessType: '',
      },
      addCompanyFormRules: {
        company: [
          { required: true, message: '请填写企业名称', trigger: 'blur' },
        ],
        businessType: [
          { required: true, message: '请选择经营类型', trigger: 'change' },
        ],
      },
      businessTypeList: BusinessTypeListEnum,
      addUserInfoVisible: false,
      addUserInfoForm: {
        name: '',
        profession: '',
      },
      addUserInfoFormRules: {
        name: [
          { required: true, message: '请填写真实姓名', trigger: 'blur' },
          { validator: nameValidator, trigger: 'blur' },
        ],
        profession: [
          { required: true, message: '请选择职业/职位', trigger: 'change' },
        ],
      },
      professionList: ProfessionEnum,
    };
  },
  mounted () {
    this.init();
  },
  methods: {
    ...mapMutations({
      updateUserInfo: 'UPDATE_USERINFO',
    }),
    handleClickOrder (status) {
      this.$router.push({
        name: 'Account',
        query: {
          status,
        },
      });
    },
    saveUserInfo () {
      this.$refs.addUserInfoForm.validate().then(async valid =>{
        if (valid) {
          // 提交信息
          await SystemSetting.employeeUpdate({
            id: this.userInfo.id,
            name: this.addUserInfoForm.name,
            profession: this.addUserInfoForm.profession,
          });
          this.$message.success('个人资料已更新');
          this.queryEmployeeMsg();
        }
      });
    },
    checkRequired () {
      this.createdDialogVisible = false;
      this.addUserInfoVisible = false;
      this.haveCompany = this.$local.get('haveCompany');
      const query = this.$route.query || {};
      if (query.loginType === 'tenant' && this.haveCompany === 'false') {
        this.createdDialogVisible = true;
      } else if (this.userInfo.isPersonal && (!this.userInfo.profession || !this.userInfo.name)) {
        this.addUserInfoVisible = true;
      }
    },
    save (type) {
      this.$refs.addCompanyForm.validate().then(async valid =>{
        if (valid) {
          const { employeeMsg } = JSON.parse(this.$local.get('userInfo'));
          const list = await createTenant({
            id: employeeMsg.id,
            tenantNames: [this.addCompanyForm.company],
            businessType: this.addCompanyForm.businessType,
          });

          await switchCompany(list.body[0].tenantId);
          this.$message.success('企业创建成功');
          this.checkRequired();
          this.updateUserInfo(JSON.parse(this.$local.get('userInfo')));

          this.$tabs.items = [];
          if (type === 1) {
            this.$router.push(
              {
                path: '/workbench',
              }).then(() => {
              localStorage.removeItem('backupsLeftMenuList');
              localStorage.removeItem('employeeMenuList');
              window.location.reload();
            });
          }
          if (type === 2) {
            setTimeout(() => {
              this.$router.push({
                name: 'IDENTIFICATION',
              });
              localStorage.removeItem('backupsLeftMenuList');
              localStorage.removeItem('employeeMenuList');
              window.location.reload();
            }, 2000);
          }
        }
      });
    },
    closeForbid () {
      const query = this.$route.query || {};
      this.haveCompany = this.$local.get('haveCompany');
      if (query.loginType === 'tenant' && this.haveCompany === 'true') {
        this.createdDialogVisible = false;
        return;
      } else if (this.userInfo.isPersonal && this.userInfo.profession && this.userInfo.name) {
        this.addUserInfoVisible = false;
        return;
      }

      const h = this.$createElement;
      this.$msgbox({
        message: h('div', { style: 'padding: 20px;' }, [
          h('i', { class: 'el-icon-warning', style: 'color:#E6A23C; font-size: 21px;margin-right: 10px' }),
          h('span', { style: 'font-size: 16px; font-weight: bold;' }, '关闭后将退出小紫平台'),
          h('p', { style: 'padding: 5px 30px;' }, '是否确认关闭？'),
        ]),
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            await AccountApi.logout();
            this.$local.clear();
            this.$session.clear();
            this.$router.replace({ name: 'LOGIN'});
            done();
          } else {
            done();
            this.checkRequired();
          }
        },
      });
    },
    isCompany () {
      if (this.userInfo.isPersonal) {
        let title = '';
        if (this.haveCompany === 'true') {
          title = '请切换至企业版本';
        }
        if (this.haveCompany === 'false') {
          title = '请先创建企业';
        }
        this.$message.warning(title);
        return false;
      }
      return true;
    },
    toJoinGongXvTong () {
      // 个人用户
      if (this.userInfo.isPersonal) {
        let ownerList = this.userInfo.adminTenantRespDTOList; // 是超级管理员的企业
        // 无企业 或者 有企业但不是企业管理员
        if (!(ownerList && ownerList.length)) {
          this.$refs.createBusiness.createdDialogVisible = true;
        } else {
          this.$refs.selectBusiness.dialogVisible = true;
        }
        return;
      }
      this.$router.push({
        name: 'JOIN_SUPPLY_DEMAND',
      });
    },
    toJoinXiaozi () {
      // if (this.userInfo.isPersonal) {
      //   let title = '';
      //   if (this.haveCompany === 'true') {
      //     title = '请切换至企业版本';
      //   }
      //   if (this.haveCompany === 'false') {
      //     title = '请先创建企业';
      //   }
      //   this.$message.warning(title);
      //   return;
      // }
      // 个人用户
      if (this.userInfo.isPersonal) {
        let ownerList = this.userInfo.adminTenantRespDTOList; // 是超级管理员的企业
        // 无企业 或者 有企业但不是企业管理员
        if (!(ownerList && ownerList.length)) {
          this.$refs.createBusiness.createdDialogVisible = true;
        } else {
          this.$refs.selectBusiness.dialogVisible = true;
        }
        return;
      }
      // 小紫供应商开通小紫加盟提示
      if (this.isSupplier) {
        this.$confirm(`【${this.userInfo.tenantName}】已是小紫合作供应商，暂时不能开通小紫加盟服务！`, '加盟提示', {
          showCancelButton: false,
          confirmButtonText: '知道了',
          type: 'warning',
        });
        return;
      }
      this.$router.push({
        name: 'JOIN_XIAOZI',
      });
    },
    toJoinYixiubao () {
      // if (this.userInfo.isPersonal) {
      //   this.$message.warning('请切换至企业版本');
      //   return;
      // }
      // 个人用户
      if (this.userInfo.isPersonal) {
        let ownerList = this.userInfo.adminTenantRespDTOList; // 是超级管理员的企业
        // 无企业 或者 有企业但不是企业管理员
        if (!(ownerList && ownerList.length)) {
          this.$refs.createBusiness.createdDialogVisible = true;
        } else {
          this.$refs.selectBusiness.dialogVisible = true;
        }
        return;
      }
      this.$router.push({
        name: 'JOIN_YIXIUBAO',
      });
    },
    toOrderWall () {
      if (this.entryStatus.tenantServiceInfoStatus !== 'use') {
        this.$confirm('开通医修保后，可查看工单数据看板，是否去开通？', '未开通医修保', {
          confirmButtonText: '去开通',
          cancelButtonText: '暂不开通',
          type: 'warning',
        }).then(() => {
          this.$router.push({
            name: 'JOIN_YIXIUBAO',
          });
        });
        return;
      }
      this.$router.push({
        name: 'ORDERWALL',
      });
    },
    noCompany () {
      this.$confirm('请先创建企业', '未查询到企业信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      });
    },
    getEnteringAuditStatus (v) {
      switch (v) {
      case '1':
        this.platformInfo[0].status = '审核中';
        break;
      case '2':
        this.platformInfo[0].status = '审核通过';
        break;
      case '3':
        this.platformInfo[0].status = '审核失败';
        break;
      case '4':
        this.platformInfo[0].status = '未提交';
        break;
      default:
        this.platformInfo[0].status = '未入驻';
      }
    },
    getTenantServiceInfo (v) {
      switch (v) {
      case 'notOpen':
        this.platformInfo[1].status = '未开通';
        break;
      case 'run':
        this.platformInfo[1].status = '试用中';
        break;
      case 'expire':
        this.platformInfo[1].status = '已到期';
        break;
      case 'use':
        this.platformInfo[1].status = '使用中';
        break;
      default:
        this.platformInfo[1].status = '未开通';
      }
    },
    async queryEmployeeMsg () {
      let { employeeMsg } = JSON.parse(this.$local.get('userInfo'));
      const info = await SystemSetting.getDetailEmploy({
        findDepartmentFlag: true,
        findRoleFlag: true,
        id: employeeMsg.id,
      });
      this.userInfo = info.body || {};
      if (!this.userInfo.id) {
        this.$local.clear();
      }

      this.$local.set('userInfo', this.userInfo);
      this.updateUserInfo(this.userInfo);

      this.checkRequired();
    },
    async init () {
      this.queryEmployeeMsg();

      const data = await OrderApi.orderCount();
      this.orderList[0].count = data.body.waitPayCount || 0;
      this.orderList[1].count = data.body.alreadyPayCount || 0;
      this.orderList[2].count = data.body.alreadyInvoiceCount || 0;

      if (!this.userInfo.isPersonal) {
        const _data = await check({operateType: '1'});
        this.isSupplier = !!_data.name || false;

        try {
          const { body } = await tenantBaseInfo({});
          this.$local.remove('entryStatus');
          if (body) {
            this.entryStatus = {
              enteringStatus: body.enteringStatus,
              enteringAuditStatus: body.enteringAuditStatus,
              tenantServiceInfoStatus: body.tenantServiceInfoStatus,
              tenantServiceInfoOrderPayStatus: body.tenantServiceInfoOrderPayStatus,
            };
            this.platformInfo[0].statusType = this.entryStatus.enteringAuditStatus;
            this.platformInfo[1].statusType = this.entryStatus.tenantServiceInfoStatus;
            this.getEnteringAuditStatus(this.entryStatus.enteringAuditStatus);
            this.getTenantServiceInfo(this.entryStatus.tenantServiceInfoStatus);
            this.$local.set('entryStatus', this.entryStatus);
          }
        } catch (error) {
          this.$local.remove('entryStatus');
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.workbench {
  display: flex;
  justify-content: space-between;
  .content {
    min-height: calc(100vh - 110px);
    display: inline-flex;
    flex-direction: column;
    .panel {
      background-color: #ffffff;
      border-radius: 6px;
      padding: 15px 20px;
      margin-bottom: 8px;
      .title {
        font-size: 16px;
        font-weight: bold;
        padding-bottom: 15px;
      }
    }
  }
  .content-left {
    margin-right: 8px;
    width: 100%;
    overflow: hidden;
  }
  .content-right {
    width: 320px;
  }
  .toPath {
    display: inline-block;
    border-radius: 4px;
    padding: 5px 20px;
    font-size: 13px;
    font-weight: 500;
    color: #FFFFFF;
    cursor: pointer;
  }
  .shuzipuhui {
    .box {
      width: 280px;
      height: 102px;
      padding-top: 10px;
      padding-left: 15px;
      .name {
        font-size: 16px;
        font-weight: 600;
      }
      .desc {
        padding-top: 5px;
        padding-bottom: 7px;
        font-size: 12px;
        font-weight: 400;
      }
    }
    .bg-1 {
      background-image: url('../../assets/imgs/workbench/bg_gongxvtong.svg');
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      .pic {
        width: 83px;
        height: 73px;
        position: absolute;
        right: 0;
        bottom: 5px;
      }
    }
    .bg-2 {
      background-image: url('../../assets/imgs/workbench/bg_xiaozijiameng.svg');
      background-repeat: no-repeat;
      background-size: cover;
    }
    .bg-3 {
      background-image: url('../../assets/imgs/workbench/bg_yixiubao.svg');
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  .yixiubao {
    .box-1 {
      width: 280px;
      height: 102px;
      background-image: url('../../assets/imgs/workbench/bg_gongdanshujukanban.svg');
      background-repeat: no-repeat;
      background-size: cover;
      padding-top: 20px;
      padding-left: 17px;
      .name {
        font-size: 15px;
        font-weight: bold;
        color: #333E5D;
        margin-bottom: 15px;
      }
    }
    .box-2 {
      width: 280px;
      height: 90px;
      background: linear-gradient(136deg, rgba(222, 233, 250, .31) 0%, rgba(230, 238, 253, .31) 100%);
      display: flex;
      justify-content: space-between;
      align-items: center;
      .cont {
        display: inline-flex;
        flex-direction: column;
        .data {
          font-size: 12px;
          font-weight: 400;
          color: #1F2733;
          display: inline-flex;
          align-items: center;
          padding: 5px 20px;
          .line {
            width: 4px;
            height: 8px;
            background: linear-gradient(180deg, #3176FF 0%, #1965FF 100%);
            margin-right: 10px;
          }
        }
      }
    }
  }
  .yundingdan {
    .box {
      display: flex;
      flex-wrap: wrap;
      width: 290px;
      .count-box {
        width: 135px;
        padding: 20px 10px;
        margin-right: 10px;
        margin-bottom: 10px;
        background: #F5F7FA;
        border-radius: 6px;
        cursor: pointer;
        .icon {
          width: 16px;
          margin: 0 5px;
        }
        .name {
          font-size: 12px;
          font-weight: 400;
          color: #1F2733;
        }
        .count {
          float: right;
          font-size: 14px;
          font-weight: bold;
          color: #1F2733;
        }
      }
    }
  }
  .created-business {
    .title {
      font-size: 14px;
      font-weight: bold;
    }
  }
}
</style>
