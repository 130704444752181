<template>
  <el-dialog title="消息详情" :visible.sync="dialogVisible" @close="handleClose" width="60vw" class="message-dialog">
    <div class="message-title">{{ detail.businessTypeName }}</div>
    <div class="message-time">{{ detail.createTime ? formatDate(detail.createTime, 'YYYY-MM-DD HH:mm:ss') : '' }}</div>
    <div class="message-remark">{{ detail.remark }}</div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose" size="small" type="primary">我知道了</el-button>
    </span>
  </el-dialog>
</template>
<script>
import * as Moment from 'dayjs';
import { mapMutations } from 'vuex';
import { updateReadFlag, queryCount } from '@/api/messages';
export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      dialogVisible: false,
    };
  },
  watch: {
    dialogVisible (v) {
      if (v) {
        updateReadFlag({ id: this.detail.id }).then(() => {
          this.queryMsgCount();
        });
      }
    },
  },
  methods: {
    ...mapMutations({
      updateUnReadCount: 'UPDATE_UNREADCOUNT',
    }),
    formatDate (text, format = 'YYYY-MM-DD') {
      return text ? Moment(text * 1).format(format) : '/';
    },
    handleClose () {
      this.$emit('close');
      this.dialogVisible = false;
    },
    async queryMsgCount () {
      const data = await queryCount({ readFlag: 0 });
      this.unReadCount = Number(data.body.unReadCount) || 0;
      this.updateUnReadCount(this.unReadCount);
    },
  },
};
</script>
<style lang="scss" scoped>
.message-dialog {
  .message-title {
    font-weight: 600;
    font-size: 16px;
    color: #1F2733;
    padding: 15px 15px 0;
  }
  .message-time {
    font-weight: 400;
    font-size: 12px;
    color: #929AA6;
    padding: 10px 15px;
  }
  .message-remark {
    height: 50vh;
    overflow-y: scroll;
    padding: 0 15px;
  }
}
</style>
