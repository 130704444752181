<template>
  <div class="notices-panel">
    <div class="top">
      <div>平台公告</div>
      <div @click="toNotices" class="more">
        <span>更多</span>
        <img :src="require('@/assets/imgs/workbench/icon_right.svg')">
      </div>
    </div>
    <template v-if="list.length">
      <div @click="handleActionClick(item)" v-for="(item, index) in list" :key="index" class="notices-box">
        <div class="title">{{ item.title }}</div>
        <div class="type-time">
          <div class="type">{{ item.typeName }}</div>
          <div class="time">{{ item.createTime ? formatDate(item.createTime, 'YYYY-MM-DD HH:mm:ss') : '' }}</div>
        </div>
      </div>
    </template>
    <Empty v-else :height="150" />

    <notice-detail-dialog ref="noticeDetailDialogRef" :detail="currentItem" />
  </div>
</template>
<script>
import * as Moment from 'dayjs';
import { announcement } from '@/api/messages';
import NoticeDetailDialog from './noticeDetailDialog.vue';
import Empty from '@/components/empty.vue';
export default {
  components: {
    NoticeDetailDialog,
    Empty,
  },
  data () {
    return {
      list: [],
      currentItem: {},
    };
  },
  methods: {
    toNotices () {
      this.$router.push({
        name: 'NOTICES',
      });
    },
    handleActionClick (item) {
      this.currentItem = item;
      this.$refs.noticeDetailDialogRef.dialogVisible = true;
    },
    formatDate (text, format = 'YYYY-MM-DD') {
      return text ? Moment(text * 1).format(format) : '/';
    },
    async getTableList () {
      let params = {
        pageNum: 1,
        pageSize: 5,
        status: 'PUBLISHED',
        orderBy: [
          {
            orderByProperty: 'lastUpdateTime',
            orderByType: 'DESC',
          },
        ],
      };
      const data = await announcement(params);
      this.list = (data.body.list || []).map(item => {
        item.contentText = item.content.replace(/(<[/]?p>)|(&nbsp;)/g, '');
        return item;
      });
    },
  },
  mounted () {
    this.getTableList();
  },
};
</script>
<style lang="scss" scoped>
.notices-panel {
  background-color: #ffffff;
  border-radius: 6px;
  padding: 15px 10px;
  margin-bottom: 8px;
  .top {
    font-size: 16px;
    font-weight: bold;
    padding: 0 10px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .more {
      font-size: 0;
      font-weight: 400;
      color: #929AA6;
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      > span {
        font-size: 14px;
      }
      > img {
        width: 20px;
        height: 24px;
        margin-left: 10px;
      }
    }
  }
  .notices-box {
    padding: 10px;
    .title {
      font-weight: 400;
      font-size: 14px;
      color: #1F2733;
      margin-bottom: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .type-time {
      display: flex;
      justify-content: space-between;
      .type {
        background: #F2F4F7;
        border-radius: 4px;
        font-weight: 400;
        font-size: 12px;
        color: #1F2733;
        padding: 1px 8px;
      }
      .time {
        font-weight: 400;
        font-size: 12px;
        color: #929AA6;
      }
    }
  }
  .notices-box:hover {
    background: #F2F4F7;
    border-radius: 6px;
    cursor: default;
  }
}
</style>
