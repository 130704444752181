import axios from 'axios';
import { Encrypt, Decrypt } from '@/utils/secret';
import { Message, Loading } from 'element-ui';
let baseURL = process.env.VUE_APP_HOST;//eslint-disable-line
const request = axios.create({
  baseURL: baseURL,
  timeout: 8000,
  headers: {
    'content-type': 'application/json',
  },
});
let requestCount = 0;
let loading;
// 请求拦截器
request.interceptors.request.use(config => { // 拦截请求，做统一处理
  const token = localStorage.getItem('token');
  // 在每个http header都加上token
  config.headers.Authorization = token;
  if (config.method !== 'get') {
    config.data = Encrypt(JSON.stringify(config.data || {}));
  }

  if (!config.stopDefaultLoading) {
    if (requestCount === 0) {
      loading = Loading.service({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
      });
    }
    // 针对流下载设置超时时间为一小时
    if (config.responseType === 'blob') {
      config.timeout = 60 * 60 * 1000;
    }
    requestCount++;
  }

  return config;
}, err => { // 失败
  return Promise.reject(err);
});
// 响应拦截器
request.interceptors.response.use(async (response) => { // 拦截响应，做统一处理
  if (!response.config.stopDefaultLoading) {
    requestCount--;
    if (requestCount === 0) {
      loading.close();
    }
  }

  if (typeof response.data === 'string' && response.config.responseType !== 'blob') {
    response.data = JSON.parse(Decrypt(response.data));
  }

  // 特殊处理下文件流下载，文件下载时系统异常后，返回的为统一的异常格式
  if (response.config?.responseType === 'blob') {
    if (response.headers['content-type'].indexOf('application/json') >= 0) {
      const blobData = await response.data.text();
      response.data = JSON.parse(blobData);
    } else {
      return { data: response.data, headers: response.headers };
    }
  }

  if (response.data.repCode && response.data.repCode === '0000' && response.data.success) {
    // 滑动验证码成功
    return response.data;
  } else if (response.data.repCode && response.data.repCode !== '0000' && !response.data.success) {
    // 滑动验证码失败
    Message({
      showClose: true,
      message: response.data.repMsg,
      type: 'error',
      dangerouslyUseHTMLString: true,
    });
    return response.data;
  } else if (response.data.heads && [200, 6001, 100002].includes(response.data.heads.code)) {
    return response.data;
  } else if (response.data.heads && response.data.heads.code === 30001) {
    Message({
      showClose: true,
      message: response.data.heads.message,
      type: 'error',
      dangerouslyUseHTMLString: true,
    });
    localStorage.clear();
    location.href = process.env.BASE_URL + '#/login';
  } else if (response.data.heads && response.data.heads.code === 506) {
    localStorage.clear();
    location.href = process.env.BASE_URL + '#/login';
    return Promise.reject(new Error('token已过期'));
  } else if (response.data.heads && response.data.heads.code === 507) {
    Message({
      showClose: true,
      message: response.data.heads.message,
      type: 'error',
      dangerouslyUseHTMLString: true,
    });
    return Promise.reject(
      {
        heads: {
          message: response.data.heads.message,
          code: -1,
        },
      },
    );
  } else {
    if (!response.config.stopDefaultMessage) {
      Message({
        showClose: true,
        message: response.data.heads.message,
        type: 'error',
        dangerouslyUseHTMLString: true,
      });
    }
    return Promise.reject(
      {
        heads: {
          message: response.data.heads.message,
          code: -1,
        },
      },
    );
  }
}, err => { // 无响应时的处理
  requestCount--;
  if (requestCount === 0) {
    loading.close();
  }
  if (axios.isCancel(err)) {
    return Promise.reject(
      {
        heads: {
          code: -100,
        },
      },
    );
  }
  let defaultMsg = '系统开了小差，请稍后再试';
  if (err.response && err.response.data && err.response.data.heads) {
    defaultMsg = err.response.data.heads.message || defaultMsg;
  }
  Message({
    showClose: true,
    message: defaultMsg,
    type: 'error',
    dangerouslyUseHTMLString: true,
  });
  return Promise.reject(
    {
      heads: {
        message: (err && err.response && err.response.status) || defaultMsg,
        code: -1,
      },
    },
  );
});
export default request;
