<template>
  <div class="authStep-box">
    <div class="part-1">
      <img :src="require('@/assets/imgs/workbench/ic_qiyerenzheng.svg')" />
      <span>您还未完成企业认证，认证成功后，解锁专属企业服务</span>
      <div @click="authentication" class="auto">立即认证</div>
    </div>
    <div class="part-2">
      <div style="font-weight: bold;">操作简单，<span style="font-size: 16px;color: #237FFA;">3步</span> 完成认证</div>
      <div class="step-box">
        <div v-for="(step, index) in stepList" :key="'step-'+index" class="step-cont">
          <div class="step">
            <img :src="step.icon" class="icon" />
            <span class="title">{{ step.title }}</span>
            <span class="desc">{{ step.desc }}</span>
          </div>
          <img v-if="index < stepList.length -1 " :src="require('@/assets/imgs/workbench/ic_right.svg')" class="right" />
        </div>
      </div>
    </div>
    <!-- 选择认证方式 -->
    <el-dialog title="请选择认证方式" :visible.sync="dialogVisible" width="720px">
      <div class="identification">
        <div class="box">
          <img :src="require('@/assets/imgs/workbench/ic_xiaozi.svg')" class="icon" />
          <div class="line-1">小紫认证-加盖公章授权认证</div>
          <div class="line-2">（审核时间：1-2个工作日）</div>
          <div class="line-3">需准备以下材料</div>
          <div class="line-box">
            <span class="point"></span>
            <span>营业执照照片</span>
          </div>
          <div class="line-box">
            <span class="point"></span>
            <span>管理员身份信息</span>
          </div>
          <div class="line-box">
            <span class="point"></span>
            <span>加盖公章的授权书</span>
            <span @click="downloadModel" class="download">下载授权书模版</span>
          </div>
          <div @click="toIdentify" class="button">去认证</div>
        </div>
        <div v-if="false" class="box">
          <img :src="require('@/assets/imgs/workbench/ic_dianziqian.svg')" class="icon" />
          <div class="line-1">电子签章-电子签章认证</div>
          <div class="line-2">（审核时间：1-2个工作日）</div>
          <div class="line-3">需准备以下材料</div>
          <div class="line-box">
            <span class="point"></span>
            <span>营业执照照片</span>
          </div>
          <div class="line-box">
            <span class="point"></span>
            <span>管理员身份信息</span>
          </div>
          <div class="line-box">
            <span class="point"></span>
            <span>加盖公章的授权书</span>
            <span class="download">下载授权书模版</span>
          </div>
          <div class="button" style="opacity: 0.5;">去认证</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { findAuthorization } from '@/utils/findAuthorization';
export default {
  data () {
    return {
      dialogVisible: false,
      stepList: [
        {
          icon: require('@/assets/imgs/workbench/ic_renzhengfangshi.svg'),
          title: '选择认证方式',
          desc: '选择小紫认证或电子签方式认证',
        },
        {
          icon: require('@/assets/imgs/workbench/ic_tianxierenzhengziliao.svg'),
          title: '填写认证资料',
          desc: '填写企业信息，上传加盖公章的企业认证公函',
        },
        {
          icon: require('@/assets/imgs/workbench/ic_renzhengziliaoshenhe.svg'),
          title: '认证资质审核',
          desc: '认证提交后，小紫平台会在1-2个工作日内进行审核',
        },
        {
          icon: require('@/assets/imgs/workbench/ic_wanchengrenzheng.svg'),
          title: '完成认证',
          desc: '解锁专属企业服务',
        },
      ],
    };
  },
  mounted () {},
  methods: {
    authentication () {
      if (!findAuthorization('homePage:certification')) {
        const title = '提示';
        const text = '暂无权限，请联系管理员进行企业认证';
        const h = this.$createElement;
        this.$msgbox({
          title: '',
          showCancelButton: false,
          showClose: true,
          confirmButtonText: '我知道了',
          message: h('div', { style: 'padding: 0 20px;' }, [
            h('i', { class: 'el-icon-error', style: 'color:#FF7A75; font-size: 17px;margin-right: 10px' }),
            h('span', { style: 'font-size: 16px; font-weight: bold; color: #1F2733;' }, title),
            h('p', { style: 'padding: 5px 10px 5px 30px; font-weight: 400; font-size: 14px; color: #1F2733;' }, text),
          ]),
        });
        return;
      }
      this.dialogVisible = true;
    },
    toIdentify () {
      this.dialogVisible = false;
      this.$router.push({
        name: 'IDENTIFICATION',
      });
    },
    downloadModel () {
      const url = `${process.env.VUE_APP_HOST}10000000000/certificationLetter.pdf`;
      const filename = '企业公函模板';
      var xhr = new XMLHttpRequest();
      xhr.open('get', url);
      xhr.responseType = 'blob';
      xhr.onload = function () {
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(xhr.response);
        link.target = '_blank';
        link.download = filename;
        link.click();
        link.remove();
      };
      xhr.send();
    },
  },
};
</script>
<style lang="scss" scoped>
.authStep-box {
  font-size: 14px;
  color: #1F2733;
  background-color: #ffffff;
  border-radius: 6px;
  padding: 15px 20px;
  margin-bottom: 8px;
  .part-1 {
    background: #E2EAFF;
    border-radius: 4px;
    padding: 13px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 83px;
    overflow: hidden;
    position: relative;
    > img {
      position: absolute;
      top: -22px;
      left: -22px;
    }
    .auto {
      display: inline-block;
      background: #237FFA;
      padding: 5px 15px;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      color: #FFFFFF;
      cursor: pointer;
    }
  }
  .part-2 {
    padding: 10px 0 15px;
    text-align: center;
    .step-box {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding-top: 25px;
      .step-cont {
        display: inline-flex;
        align-items: center;
        .step {
          display: inline-flex;
          flex-direction: column;
          align-items: center;
          padding: 0 20px;
          .icon {
            width: 42px;
            height: 42px;
            margin-bottom: 4px;
          }
          .title {
            font-size: 14px;
            font-weight: bold;
            color: #1F2733;
            margin-bottom: 10px;
            // min-width: 160px;
          }
          .desc {
            text-align: left;
            color: #929AA6;
            font-size: 12px;
          }
        }
        .right {
          width: 16px;
          height: 16px;
        }
      }

    }
  }
  .identification {
    display: flex;
    justify-content: space-around;
    padding-bottom: 50px;
    .box {
      width: 315px;
      padding: 12px 20px 20px;
      background: #F5F7FA;
      border-radius: 10px;
      .icon {
        width: 48px;
        height: 48px;
        margin-bottom: 5px;
      }
      .line-1 {
        font-size: 14px;
        font-weight: bold;
        color: #1F2733;
        margin-bottom: 5px;
      }
      .line-2 {
        font-size: 12px;
        font-weight: 400;
        color: #5F6A7A;
        margin-bottom: 20px;
      }
      .line-3 {
        font-size: 12px;
        font-weight: 400;
        color: #929AA6;
        margin-bottom: 10px;
      }
      .line-box {
        font-size: 14px;
        font-weight: 400;
        color: #1F2733;
        display: flex;
        align-items: center;
        .point {
          width: 6px;
          height: 6px;
          background: #237FFA;
          margin-right: 5px;
          border-radius: 50%;
        }
        .download {
          font-size: 12px;
          font-weight: 400;
          color: #237FFA;
          padding-left: 15px;
          cursor: pointer;
        }
      }
      .button {
        color: #ffffff;
        margin-top: 35px;
        text-align: center;
        padding: 6px;
        background: #237FFA;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
}
</style>
