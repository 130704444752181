<template>
  <el-dialog title="公告详情" :visible.sync="dialogVisible" width="60vw" class="notice-dialog">
    <div class="notice-title">{{ detail.title }}</div>
    <div class="notice-type-time">
      <div class="type">{{ detail.typeName }}</div>
      <div class="time">{{ detail.createTime ? formatDate(detail.createTime, 'YYYY-MM-DD HH:mm:ss') : '' }}</div>
    </div>
    <div v-html="detail.content" class="notice-content"></div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false" size="small" type="primary">我知道了</el-button>
    </span>
  </el-dialog>
</template>
<script>
import * as Moment from 'dayjs';
export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    formatDate (text, format = 'YYYY-MM-DD') {
      return text ? Moment(text * 1).format(format) : '/';
    },
  },
};
</script>
<style lang="scss" scoped>
.notice-dialog {
  .notice-title {
    font-weight: 600;
    font-size: 16px;
    color: #1F2733;
    padding: 15px 15px 0;
  }
  .notice-type-time {
    padding: 10px 15px;
    .type {
      display: inline-block;
      background: #F2F4F7;
      border-radius: 4px;
      padding: 0 6px;
      font-weight: 400;
      font-size: 12px;
      color: #1F2733;
      margin-right: 10px;
    }
    .time {
      display: inline-block;
      font-weight: 400;
      font-size: 12px;
      color: #929AA6;
    }
  }
  .notice-content {
    height: 50vh;
    overflow-y: scroll;
    padding: 0 15px;
  }
}
</style>
