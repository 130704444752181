<template>
  <div class="deck-box">
    <div class="header">
      <b>驾驶舱</b>
    </div>
    <div class="list">
      <div v-for="(item, i) in list" :key="'deck-'+i" class="item">
        <div class="class">{{ item.label }}</div>
        <div class="deck-panel">
          <div v-for="(deck, j) in item.value" :key="j" class="deck">
            <span class="deck-label">{{ deck.label }}</span>
            <span class="deck-value">{{ deck.value }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  watch: {
    activeTab () {
      this.init();
    },
  },
  data () {
    return {
      activeTab: '1',
      statusTabs: [
        {
          label: '我的待办',
          value: '1',
          count: 0,
        },
        {
          label: '抄送我的',
          value: '2',
          count: 0,
        },
        {
          label: '我发起的',
          value: '3',
        },
        {
          label: '我的已办',
          value: '4',
        },
      ],
      list: [
        {
          label: '销售',
          value: [
            { label: '待接单', value: 0 },
            { label: '待报价', value: 0 },
            { label: '待确认报价', value: 0 },
            { label: '合同待审核', value: 0 },
            { label: '订单待发货', value: 0 },
            { label: '售后待审核', value: 0 },
            { label: '售后待签收', value: 0 },
          ],
        },
        {
          label: '履约',
          value: [
            { label: '待签收寄修单', value: 0 },
            { label: '待寄回寄修单', value: 0 },
            { label: '待签收备用机', value: 0 },
            { label: '待指派', value: 0 },
            { label: '待接单', value: 0 },
            { label: '待完工', value: 0 },
          ],
        },
        {
          label: '结算',
          value: [
            { label: '对账单待确认', value: 0 },
          ],
        },
      ],
    };
  },
  mounted () {
    this.init();
  },
  methods: {
    async queryPageList () {
      // let params = {
      //   pageNum: this.pageNum,
      //   pageSize: this.pageSize,
      // };
      // const data = await queryPage(params);
      // if (data.body.list) {
      //   if (data.body.list.length === params.pageSize) {
      //     this.isLastPage = false;
      //   } else {
      //     this.isLastPage = true;
      //   }

      //   if (this.pageNum === 1) {
      //     this.list = data.body.list;
      //   } else {
      //     this.list = this.list.concat(data.body.list);
      //   }
      // }
    },
    init () {
      this.queryPageList();
    },
  },
};
</script>
<style lang="scss" scoped>
.deck-box {
  font-size: 14px;
  color: #1F2733;
  background-color: #ffffff;
  border-radius: 6px;
  padding: 15px 20px;
  margin-bottom: 8px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
  }
  .list {
    min-height: 250px;
    padding-top: 15px;
    .item {
      padding-bottom: 10px;
      .class {
        padding-top: 10px;
        font-weight: bold;
        font-size: 14px;
        color: #333333;
        &::before {
          content: ' ';
          display: inline-block;
          width: 2px;
          height: 12px;
          background: #237FFA;
          margin-right: 6px;
        }
      }
      .deck-panel {
        margin-top: 10px;
        .deck {
          min-width: 160px;
          cursor: default;
          display: inline-flex;
          justify-content: space-between;
          align-items: center;
          border-radius: 6px;
          border: 1px solid #EBEDF0;
          padding: 12px 10px;
          margin-right: 10px;
          margin-bottom: 10px;
          .deck-label {
            font-weight: 400;
            font-size: 14px;
            color: #1F2733;
            padding-right: 20px;
          }
          .deck-value {
            font-size: 20px;
            color: #1F2733;
          }
        }
        .deck:hover {
          background: #F5F7FA;
          border: 1px solid #F5F7FA;
        }
      }
    }
  }
}
</style>
