<template>
  <div class="process-approval-box">
    <div class="header">
      <div class="ctrl-bar-left">
        <b class="panel">流程审批</b>
        <div class="status-box">
          <span
            @click="() => activeTab = status.value"
            v-for="(status, index) in statusTabs"
            :key="'status-'+index"
            :class="['status', activeTab === status.value ? 'active' : '']">
            {{ status.label }}{{ status.count ? `(${status.count})` : '' }}
          </span>
        </div>
      </div>
      <div class="ctrl-bar-right">
        <div @click="init" class="refresh">刷新</div>
        <div @click="toApprovalLists" class="more">
          <span>更多</span>
          <img :src="require('@/assets/imgs/workbench/icon_right.svg')">
        </div>
      </div>
    </div>
    <div v-if="list && list.length" class="list">
      <div v-for="(item, index) in list" :key="'approval-'+index" class="item">

      </div>
    </div>
    <Empty v-else />
  </div>
</template>
<script>
import * as Moment from 'dayjs';
import Empty from '@/components/empty.vue';
export default {
  components: {
    Empty,
  },
  watch: {
    activeTab () {
      this.init();
    },
  },
  data () {
    return {
      activeTab: '1',
      statusTabs: [
        {
          label: '我的待办',
          value: '1',
          count: 0,
        },
        {
          label: '抄送我的',
          value: '2',
          count: 0,
        },
        {
          label: '我发起的',
          value: '3',
        },
        {
          label: '我的已办',
          value: '4',
        },
      ],
      pageNum: 1,
      pageSize: 5,
      currentItem: {},
      list: [],
    };
  },
  mounted () {
    this.init();
  },
  methods: {
    formatDate (text, format = 'YYYY-MM-DD') {
      return text ? Moment(text * 1).format(format) : '/';
    },
    toApprovalLists () {

    },
    async queryPageList () {
      // let params = {
      //   pageNum: this.pageNum,
      //   pageSize: this.pageSize,
      // };
      // const data = await queryPage(params);
      // if (data.body.list) {
      //   if (data.body.list.length === params.pageSize) {
      //     this.isLastPage = false;
      //   } else {
      //     this.isLastPage = true;
      //   }

      //   if (this.pageNum === 1) {
      //     this.list = data.body.list;
      //   } else {
      //     this.list = this.list.concat(data.body.list);
      //   }
      // }
    },
    init () {
      this.pageNum = 1;
      this.list = [];
      this.queryPageList();
    },
  },
};
</script>
<style lang="scss" scoped>
.process-approval-box {
  font-size: 14px;
  color: #1F2733;
  background-color: #ffffff;
  border-radius: 6px;
  padding: 0 20px;
  margin-bottom: 8px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .ctrl-bar-left {
      display: inline-flex;
      align-items: center;
      .panel {
        font-size: 16px;
        font-weight: bold;
      }
      .status-box {
        margin-left: 20px;
        .status {
          cursor: default;
          margin-right: 10px;
          background: #F2F4F7;
          border-radius: 4px;
          padding: 4px 8px;
          font-weight: 400;
          font-size: 14px;
          color: #5F6A7A;
          &.active {
            background: #4A9EFF;
            color: #FFFFFF;
          }
        }
      }
    }
    .ctrl-bar-right {
      display: inline-flex;
      align-items: center;
      .refresh {
        font-weight: 400;
        font-size: 14px;
        color: #929AA6;
        margin-right: 15px;
        cursor: pointer;
      }
      .more {
        font-size: 0;
        font-weight: 400;
        color: #929AA6;
        padding: 15px 0;
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        > span {
          font-size: 14px;
        }
        > img {
          width: 20px;
          height: 24px;
          margin-left: 10px;
        }
      }
    }
  }
  .list {
    min-height: 250px;
    padding: 20px 0;
    .item {
      padding-bottom: 20px;
      cursor: pointer;
    }
  }
}
</style>
